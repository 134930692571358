/* styles for LoginScreen component */
.login-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
}

.logo {
  max-width: 75%;
  margin-bottom: 30px;
}

.login-failed {
  margin-bottom: 30px;
}

.input-field {
  width: 75%;
}

.password-field {
  width: 75%;
  margin-bottom: 4px;
}

.login-button {
  width: 75%;
  margin-top: 8px;
  margin-bottom: 18px;
}

.logout-button {
  width: 75%;
}
